import { useContext, useMemo, useState } from 'react'
import { type FormikHelpers } from 'formik'

import { type Template } from '../types'
import { useUpdateTemplate } from '../api/template'
import MessageContext from '../contexts/MessageContext'

type UseTemplate = {
  data?: Template
  fields: string[]
  selectedTab: number
  groupedFields: GroupedFields[]
  tabNames: string[]
  onSubmit: (values: Template, helpers: FormikHelpers<Template>) => void
  switchTab: (newTabIdx: number) => void
}

type UseTemplateProps = {
  data: Template
  filter?: string
}

type TemplateFieldGroup = {
  name: string
  test: (fieldName: string) => boolean
  color?: string
  isDefault?: boolean
}

type GroupedFields = {
  group: TemplateFieldGroup
  fields: string[]
}

const defaultFieldGroup: TemplateFieldGroup = {
  name: 'Ungrouped',
  test: (v: any) => true,
  isDefault: true
}
const fieldGroupsByTab: Record<string, TemplateFieldGroup[]> = {
  frame: [
    {
      name: 'Base',
      test: (fieldName: string) => fieldName.startsWith('frame_base')
    },
    {
      name: 'Utils',
      test: (fieldName: string) => ['frame_description_template', 'frame_keywords_template', 'frame_prompt_template'].includes(fieldName)
    },
    {
      name: 'Formatting',
      test: (fieldName: string) => fieldName.includes('_format_')
    }
  ],
  ideas: [
    {
      name: 'Base',
      test: (fieldName: string) => fieldName.startsWith('ideas_base')
    },
    {
      name: 'Utils',
      test: (fieldName: string) => ['frame_description_template', 'frame_keywords_template', 'frame_prompt_template'].includes(fieldName)
    },
    {
      name: 'Formatting',
      test: (fieldName: string) => fieldName.includes('_format_')
    }
  ],
  content: [
    {
      name: 'Tone',
      test: (fieldName: string) => fieldName.startsWith('content_change_tone')
    },
    {
      name: 'Stylizing',
      test: (fieldName: string) => fieldName.startsWith('content_stylize')
    },
    {
      name: 'Personalization',
      test: (fieldName: string) => fieldName.startsWith('content_personalize')
    }
  ],
  page: [
    {
      name: 'Base',
      test: (fieldName: string) => ['page_purpose_template', 'page_description_template', 'page_intents_template', 'page_audience_template'].includes(fieldName)
    },
    {
      name: 'Meta',
      test: (fieldName: string) => ['page_description_misalignments_template', 'page_meta_suggestion_template', 'page_use_meta_suggestion_template'].includes(fieldName)
    },
    {
      name: 'Content',
      test: (fieldName: string) => ['page_gaps_template', 'page_gaps_suggestions_template', 'page_final_suggestions_template', 'page_meta_suggestion_template', 'page_use_content_suggestion_template'].includes(fieldName)
    }
  ],
  post: [
    {
      name: 'Base',
      test: (fieldName: string) => fieldName.startsWith('post_base')
    }
  ]
}

const generalTabPrefixes = ['company', 'existed', 'image', 'knowledge', 'rank']

const useTemplate = ({ data, filter }: UseTemplateProps): UseTemplate => {
  const addMessage = useContext(MessageContext)
  const updateTemplate = useUpdateTemplate()
  const [selectedTab, setSelectedTab] = useState(0)

  const fields = useMemo<string[]>(() => {
    return data?.params.common ? Object.keys(data.params.common).sort() : []
  }, [data])

  // map each field to own tab
  const tabNamesMap = useMemo<Record<string, string>>(() => {
    const tabMap: Record<string, string> = {}

    fields.forEach((key) => {
      const tabName = key.split('_')[0]

      const shouldBeInGeneralTab = generalTabPrefixes.includes(tabName)

      shouldBeInGeneralTab ? (tabMap[tabName] = 'general') : (tabMap[tabName] = tabName)
    })

    return tabMap
  }, [fields])

  // array of unique tab names
  const tabNames = useMemo<string[]>(() => {
    return Array.from(new Set(fields.map((key) => tabNamesMap[key.split('_')[0]])))
  }, [fields, tabNamesMap])

  // filter fields to display based on active tab & search term
  const filteredFields = useMemo<string[]>(() => {
    const selectedTabName = tabNames[selectedTab]
    const fieldsForTab = fields.filter((key) => tabNamesMap[key.split('_')[0]] === selectedTabName)

    if (filter) {
      return fieldsForTab.filter((key) => key.toLowerCase().includes(filter.toLowerCase() || ''))
    }

    return fieldsForTab
  }, [fields, selectedTab, tabNames, tabNamesMap, filter])

  // get field groups for active tab
  const fieldGroups = useMemo<TemplateFieldGroup[]>(
    () =>
      [...(fieldGroupsByTab[tabNames[selectedTab]] || []), defaultFieldGroup]
        .reverse()
        .map((group, groupIndex) => ({ ...group, color: groupIndex ? '#9575cd' : '#bdbdbd' }))
        .reverse(),
    [tabNames, selectedTab]
  )

  // grouping fields
  let fieldsCounter = [...filteredFields]
  const groupedFields = useMemo<GroupedFields[]>((): GroupedFields[] => {
    return fieldGroups.map((fGroup: TemplateFieldGroup): GroupedFields => {
      const groupFields: string[] = []
      fieldsCounter = fieldsCounter.filter((field) => {
        if (fGroup.test(field)) {
          groupFields.push(field)
          return false
        }
        return true
      })

      return { group: fGroup, fields: groupFields }
    })
  }, [filteredFields, fieldGroups])

  const onSubmit = (values: Template, helpers: FormikHelpers<Template>): void => {
    helpers.setSubmitting(true)
    updateTemplate
      .mutateAsync(values)
      .catch((err) => {
        addMessage({
          id: Date.now(),
          message: err.response?.data || err.message
        })
      })
      .finally(() => {
        helpers.setSubmitting(false)
      })
  }

  return {
    data,
    fields: filteredFields,
    groupedFields,
    selectedTab,
    tabNames,
    onSubmit,
    switchTab: setSelectedTab
  }
}

export default useTemplate
